/* 600/16 */
/* 801/16 */
/* 1100/16 */
/* 768/16 */
/* Outer */
.popup {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.75);
}
/* Inner */
.popup-inner {
  max-width: 700px;
  width: 90%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px #000000;
  border-radius: 3px;
  background: #fff;
  color: #3e3e3e;
}
@media (max-width: 37.5em) {
  .popup-inner {
    width: 80%;
  }
}
.popup-inner.confirmation {
  display: none;
}
.popup-inner h2 {
  background: #f7f7f7;
  text-align: center;
  width: calc(100% - 20px);
  padding: 10px;
  text-transform: uppercase;
  font-weight: 300;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 10px;
}
.popup-inner p {
  background: #f7f7f7;
  padding: 10px;
  font-weight: 300;
  width: calc(100% - 20px);
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.popup-inner label {
  font-weight: 400;
  padding: 10px 0 5px;
  display: block;
  color: #9f9f9f;
}
.popup-inner select,
.popup-inner textarea {
  padding: 10px;
  border: 1px solid #b7b7b7;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  background-color: #fff;
}
.popup-inner textarea {
  width: calc(100% - 22px);
  max-width: calc(100% - 22px);
}
.popup-inner .btn {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding: 8px;
  background: #57bf93;
  display: block;
  float: left;
  text-decoration: none;
  letter-spacing: 1px;
  margin: 10px 10px 0 0;
}
/* Close Button */
.popup-close {
  width: 30px;
  height: 30px;
  padding-top: 4px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: ease 0.25s all;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 1000px;
  background: rgba(0, 0, 0, 0.8);
  font-family: Arial, Sans-Serif;
  font-size: 20px;
  text-align: center;
  line-height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.popup-close:hover {
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  background: #000000;
  text-decoration: none;
}
